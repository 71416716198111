.nav {
    background: $white;
    .navbar-inner {
        padding: 0 calc(var(--bs-gutter-x, .75rem) * 2);
    }
    .search-input {
        width: auto;
        &.input-group {
            .input-group-text {
                padding-right: 0;
                border-right: 0;
            }
            .form-control {
                border-left: 0;
                &:focus {
                    box-shadow: unset;
                    border-color: $input-border-color;
                }
            }
            &:focus-within {
                .input-group-text , .form-control{
                    border-color: $input-focus-border-color;
                }
                box-shadow: $component-active-shadow;
            }
        }
    }
    .navbar-brand{
        display: flex;
        align-items: center;
        .logo-title{
            margin-left: $spacer;
            color: $dark;
            margin-bottom: 0;
            z-index: 1;
        }
    } 
    .sidebar-toggle {
        height: 26px;
        width: 26px;
        line-height: 19px;
        text-align: center;
        position: absolute;
        left: 20px;
        right: auto;
        top: auto;
        cursor: pointer;
        background: $primary;
        color: $white;
        z-index: $zindex-general + 11;
        display: none;
        padding: 0.2rem;
        @if $enable-rounded == true {
            border-radius: $border-radius-lg;
        }
        @if $enable-shadows == true {
            box-shadow: $component-active-shadow;
        }
    }
    .navbar-toggler {
        .navbar-toggler-icon {
            background-image: none;
            .navbar-toggler-bar {
                display: block;
                position: relative;
                width: 22px;
                height: 1px;
                border-radius: 1px;
                background: $body-color;
                transition: all 400ms ease;
                margin: 0 auto;
            }
            .navbar-toggler-bar.bar2 {
                margin-top: 7px;
            }
            .navbar-toggler-bar.bar3 {
                margin-top: 7px;
            }
        }
    }
    .navbar-toggler[aria-expanded=true] {
        .navbar-toggler-bar.bar1 {
            transform: rotate( 45deg );
            transform-origin: 10% 10%;
            margin-top: 4px;
        }
        .navbar-toggler-bar.bar2 {
            opacity: 0;
        }
        .navbar-toggler-bar.bar3 {
            transform: rotate( -45deg );
            transform-origin: 10% 90%;
            margin-top: 3px;
        }
    }
    
       
}
//navbar color
.iq-navbar.navs-color {
	background: $primary;
    .navbar-nav{
		.nav-item{
			.nav-link{
				color: $white;
            }
        }
    }
}
.iq-navbar {
    z-index: $zindex-general - 10;
    &.nav-glass{
        background:linear-gradient(120deg, rgba($white, 0.5) -8%, rgba($white, 0.01) 120%);
        backdrop-filter: blur(10px);
        border-bottom: $border-width solid $white;
        .navbar-nav {
            @include top-nav-link;
        }
    }
    &.navbar-expand-lg {
        &.navs-transparent {
            background: transparent;
            @include top-nav-link;
        }
    }
    &.navs-sticky {
        position: sticky;
        top: 0;
        width: 100%;
    }
    .dropdown {
        .dropdown-menu[data-bs-popper] {
            top: 110%;
            -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        }
        .dropdown-menu {
            &.sub-drop {
                width: 300px;
                border-radius: $border-radius;
                -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                li {
                    a {
                        color: $body-color;
                    }
                }

                .iq-sub-card {
                    cursor: pointer;
                    padding: .75rem 1.25rem !important;
                    display: inline-block;
                    width: 100%;
                    color: $body-color;
                    transition: all 400ms ease;
                    &:hover{
                        background: tint-color($primary, 90%)
                    }
                    &:not(:last-child) {
                        border-bottom: $border-width solid $border-color;
                    }
                    &:last-child {
                        border-bottom-left-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
    }
}
.iq-navbar-header {
    background: $nl3navy;
    .iq-header-img {
        overflow: hidden;
        border-radius: $border-radius-lg;
        img {
            object-fit: cover;
        }
    }
    &.navs-bg-color {
        color: $white;
        .iq-header-img {
            background: $primary;
            img {
                display: none;
            }
        }
    }
}
  
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      -ms-opacity: 0;
      -o-opacity: 0;
      opacity: 0; }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      -ms-opacity: 1;
      -o-opacity: 1;
      opacity: 1; } }
  
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      -ms-opacity: 0;
      -o-opacity: 0;
      opacity: 0; }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      -ms-opacity: 1;
      -o-opacity: 1;
      opacity: 1; } }
@include media-breakpoint-down(xl) {
    .nav {
        .sidebar-toggle {
            display: block;
        }
    }
}

@include media-breakpoint-down(lg){
    .iq-navbar {
            .navbar-collapse {
                &:not(.offcanvas-collapse) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                flex-grow: unset;
                background: $white;
                z-index: 11;
                box-shadow: $box-shadow-sm;
                transition: all 400ms ease;
            }
            .navbar-nav.navbar-list{
                &:not(.offcanvas-collapse) {
                    flex-direction: row;
                    float: right;
                    // li {
                    //     padding:.5rem 1.5rem .0rem 0rem !important
                    // }
                }
            }
        }
    }
}

//UI kit Navbars
.header{
    background-size: cover;
    background-repeat: no-repeat;
    height: 18.75rem;
    .navbar-light{
        .navbar-toggler{
            .navbar-toggler-icon{
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
        }
        .navbar-nav{
            .nav-item{
                .nav-link{
                    color: $black;
                    @include hover-focus-active {
                    color: $purple;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .iq-navbar {
        .navbar-collapse{
            .navbar-nav{
                li{
                    position: static;
                    .sub-drop{
                        &.dropdown-menu{
                            margin: auto;
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
    }
}
