.account-table {
    position: relative;
    height: 600px;
    overflow: auto;
}

.admin-table {
    position: relative;
    height: 300px;
    overflow: auto;
}

.history-table {
    position: relative;
    height: 470px;
    overflow: auto;
}

.table-wrapper-scroll-y {
    display: block;
}

.mapBox {
    width: 100%;
    height: window.innerHeigth;
    display: flex;
    flex-wrap: wrap;
}

.mapBox div:first-child {
    position: relative !important;
}

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #4052A4;
  border-color: #4052A4;
}

.table .thead-light th {
  color: #000000;
  background-color: #FFFFFF;
  border-color: #000000;
}

.map-container {
  height: 100%;
  width: 100%;
}

.apiKeyText {
    font-family: "sans-serif";
}

.linkAcctButton {
    padding: 5px 0;
    width: 250px;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.linkAcctButton:hover {
    background: #23b746;
}

.actnButton {
    padding: 5px 0;
    width: 140px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.actnButton:hover {
    background: #23b746;
}
.dlButton {
    padding: 5px 0;
    width: 5.0rem;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: center;
    border: none;
    outline: 2px;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.dlButton:hover {
    background: #FFFFFF;
}

.formButton {
    padding: 5px 0;
    width: 100px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.formButton:hover {
    background: #23b746;
}

.keyButton {
    padding: 5px 0;
    width: 60px;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.keyButton:hover {
    background: #23b746;
}

.copyLink {
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.copyLink:hover {
    background: #23b746;
}

.detailsButton {
    padding: 5px 0;
    width: 80px;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.detailsButton:hover {
    background: #23b746;
}

.mySpan {
    color: #081123;
}
.mySpan:hover {
    color: #384CB7;
}

.myHdrImg {
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.caption-title {
    color: #ffffff;
}

.caption-sub-title {
    color: #ffffff;
}

.sub-header {
    color: #ffffff;
}

.nl3link {
    color: #081123;
}

.nl3link:hover {
    color: #384CB7;
}

.nl3wlink {
    color: #000000;
}

.nl3wlink:hover {
    color: #6387fc;
}

.nl3blink {
    color: #6387fc;
}

.nl3blink:hover {
    color: #ffffff;
}

.login-component {
    /* The image used */
    background-image: url(./images/login-background-solid.png);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.company-login-component {
    /* The image used */
    background-image: url(./images/company-background.png);

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.counter {
    color: #ffffff;
}

.whiteCard {
    background: #ffffff;
    color: black;
    border-radius: 5px;
}

.charcoalCard {
    background: #363333;
    color: white;
    border-radius: 5px;
}

.purpleCard {
    background: #4052A4;
    color: white;
    border-radius: 5px;
}

.ltRedCard {
    background: #F3BAB6;
    color: white;
    border-radius: 5px;
}

.greyCard {
    background: #F5F5F5;
    color: black;
    border-radius: 5px;
}

.tanCard {
    background: #F0E68C;
    color: black;
    border-radius: 5px;
}

.blackText {
    color: #000000;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal_content {
  background-color: #72A0C1;
  color: #ffffff;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  overflow: auto; /* Enable scroll if needed */
  word-wrap: break-word;
  width: 70%; /* Could be more or less, depending on screen size */
}

.modal_title {
  color: #ffffff;
  align: center;
  font-size: 28px;
  font-weight: bold;
}

.modal_text {
  color: #ffffff;
  align: left;
  font-size: 18px;
}


.close {
  color: #ffffff;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nl3Window {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.nl3Window_guide {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.nl3Window_firebase {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.nl3Window_content {
  background-color: #ffffff;
  color: #000000;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  overflow: auto; /* Enable scroll if needed */
  word-wrap: break-word;
  margin-top: 10%;
  margin-left: 20%;
  width: 70%; /* Could be more or less, depending on screen size */
}

.nl3Window_title {
  color: #000000;
  align: center;
  font-size: 28px;
  font-weight: bold;
}

.nl3Window_text {
  color: #000000;
  align: left;
  font-size: 16px;
}

.nl3EULA_text {
  color: #000000;
  align: left;
  font-size: 10px;
  overflow: auto; /* Enable scroll if needed */
  word-wrap: break-word;
  height: 250px;
  border: 2px solid #000000;
}

.nl3Window_close {
  color: #000000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.nl3Window_close:hover,
.nl3Window_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nl3Tooltip {
  background: white !important;
  color: black !important;
  font-size: 16px !important;
}

.downloadButton {
    padding: 5px 0;
    width: 210px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #ffffff;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.downloadButton:hover {
    background: #23b746;
}

.clearButton {
    padding: 5px 0;
    width: 150px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: none;
    font-family: sans-serif;
    color: #000000;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.clearButton:hover {
    background: none;
    color: #c43434;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

ul.pagination li.page-item.active a.page-link {
  color: #445565 !important;
  background-color: #e3e7eb !important;
  border-color: #ced4da !important;
}

ul.pagination a.page-link {
  padding: 0.20rem 0.20rem;
  min-width: 2.0rem;
  text-align: center;
  box-shadow: none !important;
  border-color: #ced4da !important;
  color: #4052A4;
  font-weight: 800;
  font-size: 1rem;
}

ul.pagination a.page-link:hover {
  background-color: #f4f4f4;
}

tr.expandable > td {
  /* background-color: #e0e0e0;
  box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, .2); */
  padding: 0;
  border: 0;
}

tr.expandable > td > .inner {
  /* background-color: #e0e0e0; */
  margin: 15px;
  border: 0;
  overflow: hidden;
}

.nl3ImportIcon {
    padding: 5px 0;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #ffffff;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.nl3ImportIcon:hover {
    background: #23b746;
}

.nl3MFAWindow {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.nl3MFAWindow_content {
  background-color: #ffffff;
  color: #000000;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  overflow: auto; /* Enable scroll if needed */
  word-wrap: break-word;
  width: 70%; /* Could be more or less, depending on screen size */
}

.nl3MFAWindow_title {
  color: #000000;
  align: center;
  font-size: 28px;
  font-weight: bold;
}

.nl3MFAWindow_text {
  color: #000000;
  align: left;
  font-size: 16px;
}

.nl3MFAWindow_close {
  color: #000000;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.nl3MFAWindow_close:hover,
.nl3MFAWindow_close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.nl3IdleWindow {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.nl3IdleWindow_content {
  background-color: #ffffff;
  color: #000000;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  overflow: auto; /* Enable scroll if needed */
  word-wrap: break-word;
  width: 70%; /* Could be more or less, depending on screen size */
}

.nl3IdleWindow_title {
  color: #000000;
  align: center;
  font-size: 28px;
  font-weight: bold;
}

.nl3IdleWindow_text {
  color: #000000;
  align: left;
  font-size: 16px;
}

.notifButton {
    padding: 5px 0;
    width: 140px;
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
    position: relative;
    border: none;
    outline: none;
    background: #4052A4;
    font-family: sans-serif;
    color: #FFFFFF;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.notifButton:hover {
    background: #23b746;
}

.jit-templates .card-body {
  color: black;
}

.nl3-notification-item {
  color: #333333;
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  border-color: #CDD4DA;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
}

.nl3-notification-header {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}

.nl3-notification-attachment-header {
  font-weight: bold;
  font-size: 14px;

}

.nl3-notification-info-container{
  background-color: #E3F7FC;
  border-color: #BAE7F9;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #333333;
  font-size: 12px;
}

.nl3-notification-actions{
  text-align: right;
}

.nl3-notification-button{
  margin-left: 10px;
  font-size: 14px !important;
  border-radius: 20px !important;
  padding: 5px 15px;
}
/*
.nl3-notification-approve {
  background-color: #28a745;
  border-color: #28a745;;
}

.nl3-notification-button-reject{
  background-color: #dc3545;

} */

.MuiTableRow-head  {
  background-color: #384CB7;
}


.MuiTableRow-head  TH{
  color: #FFFFFF;
}

.MuiTableBody-root  tr:nth-child(even) {
  background-color: #F8F8F8;
}

.nl3-no-records-in-table {
  padding: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
  width: 100%;
  text-align: center;
}
